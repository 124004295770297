import request from '@/utils/request';

const PATH = '/role';

export const getRoleList = () => request({
  url: PATH,
  method: 'get',
});

export const getRole = (roleCode: string) => request({
  url: `${PATH}/${roleCode}`,
  method: 'get',
});
